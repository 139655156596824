<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Outlet
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Master</li>          
          <li class="breadcrumb-item">Area</li>					
          <li class="breadcrumb-item next-breadcrumb"><router-link class="nav-link" :to="`/master/area/`"><span class="menu-title">Provinces</span></router-link></li>
          <li class="breadcrumb-item next-breadcrumb"><router-link class="nav-link" :to="`/master/area/provinces/${$route.params.uuid_provinces}/regencies/`"><span class="menu-title">Regencies</span></router-link></li>
          <li class="breadcrumb-item" aria-current="page">District</li>          
        </ol>
      </nav>
    </div>
    <div class="row" ref="containerMain">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<div class="row">
									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Province</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.provinces_name" class="form-control form-control-lg" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Regencies</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.regencies_name" class="form-control form-control-lg" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>District</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.districts_name" class="form-control form-control-lg" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Villages</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.villages_name" class="form-control form-control-lg" disabled="disabled">
									</div>                                    
								</div>
							</div>
						</div>													
					</div>
				</div>
			</div>
      
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-body">
            <b-tabs content-class="mt-3">
              <b-tab title="Outlet" active>
                <div class="row col-lg-12 float-none d-block mt-3 pt-3">
                  <h4 class="text-center">Outlet</h4>
                </div>

                <!-- <div class="row col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2">
                  <button type="button"  
                    class="btn btn-sm btn-primary btn-icon-text"
                    @click="openAddModal()">
                    Add
                    <i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
                  </button>							
                </div>                                                 -->

                <div class="row col-lg-12">
                  <b-form-group
                    label="Filter"
                    label-for="filter-input"
                    label-cols-md="3"
                    label-align-md="right"
                    label-size="md"
                    class="mt-3 pt-3 mb-1"
                  >
                    <b-input-group size="md">
                      <b-form-input
                        id="filter-input"
                        type="search"
                        placeholder="Type to Search"
                        style="margin-right: 0.5rem"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          style="margin-right: 0.5rem"
                          >Search</b-button
                        >
                        <b-button >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>

                <b-table 
                responsive 
                show-empty
                class="pt-4"
                :striped="tableConfig.striped"
                :bordered="tableConfig.bordered"
                :borderless="tableConfig.borderless"
                :outlined="tableConfig.outlined"
                :small="tableConfig.small"
                :hover="tableConfig.hover"
                :dark="tableConfig.dark"
                :fixed="tableConfig.fixed"
                :foot-clone="tableConfig.footClone"
                :no-border-collapse="tableConfig.noCollapse"
                :head-variant="tableConfig.headVariant"
                :table-variant="tableConfig.tableVariant"
                :items="dataOutlet" 
                :fields="fieldsOutlet">
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>						
                  <template v-slot:cell(actions)="row">
                    <button type="button"  
                      class="btn btn-sm btn-success btn-icon-text"
                      @click="openLink('detail',row.item)">
                      Detail
                      <i class="mdi mdi-map btn-icon-append"></i>
                    </button>
                    <br>
                    <br>

                    <a :href="`https://www.google.com/maps/search/?api=1&query=${row.item.latitude},${row.item.longitude}`" target="_blank">
                      <button type="button"  
                        class="btn btn-sm btn-success btn-icon-text">
                        Open Map
                        <i class="mdi mdi-file-check btn-icon-append"></i>
                      </button>
                    </a>
                    <br>
                    <br>

                    
                    <!-- <button type="button"  
                      class="btn btn-sm btn-warning btn-icon-text"
                      @click="openEditModal(row.item.uuid, row.item)">
                      Edit
                      <i class="mdi mdi-file-check btn-icon-append"></i>
                    </button>
                    <br>
                    <br> -->

                    <!-- <button type="button"  
                      class="btn btn-sm btn-danger btn-icon-text"
                      @click="openDeleteModal(row.item.uuid, row.item)">
                      Delete
                      <i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
                    </button> -->
                    <br>
                  </template>							
                </b-table>
                <b-pagination
                  v-model="page"
                  :total-rows="totalRowsOutlet"
                  :per-page="limit"
                  align="fill"
                  size="md"
                  class="mb-1"
                  v-on:click.native="callback"
                ></b-pagination>
                <b-row class="pt-4">
                  <p class="col-lg-12 mb-1">Total Data : {{ dataOutlet.length }}</p>                
                  <p class="col-lg-12 mb-1">Total Data per Page : {{ totalRowsOutlet }}</p>              
                  <p class="col-lg-12 mb-1">Total Page : {{ lastPageOutlet }}</p>
                </b-row>                
              </b-tab>
              <b-tab title="Courier">
                <div class="row col-lg-12 float-none d-block mt-3 pt-3">
                  <h4 class="text-center">Courier</h4>
                </div>

                <!-- <div class="row col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2">
                  <button type="button"  
                    class="btn btn-sm btn-primary btn-icon-text"
                    @click="openAddModal()">
                    Add
                    <i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
                  </button>							
                </div>                                                 -->

                <div class="row col-lg-12">
                  <b-form-group
                    label="Filter"
                    label-for="filter-input"
                    label-cols-md="3"
                    label-align-md="right"
                    label-size="md"
                    class="mt-3 pt-3 mb-1"
                  >
                    <b-input-group size="md">
                      <b-form-input
                        id="filter-input"
                        type="search"
                        placeholder="Type to Search"
                        style="margin-right: 0.5rem"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          style="margin-right: 0.5rem"
                          >Search</b-button
                        >
                        <b-button >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>

                <b-table 
                responsive 
                show-empty
                class="pt-4"
                :striped="tableConfig.striped"
                :bordered="tableConfig.bordered"
                :borderless="tableConfig.borderless"
                :outlined="tableConfig.outlined"
                :small="tableConfig.small"
                :hover="tableConfig.hover"
                :dark="tableConfig.dark"
                :fixed="tableConfig.fixed"
                :foot-clone="tableConfig.footClone"
                :no-border-collapse="tableConfig.noCollapse"
                :head-variant="tableConfig.headVariant"
                :table-variant="tableConfig.tableVariant"
                :items="dataCourier" 
                :fields="fieldsCourier">
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>						
                  <template v-slot:cell(created_at)="row">
                    {{ normalDateTime(row.item.created_at) }}
                  </template>
                  <template v-slot:cell(updated_at)="row">
                    {{ normalDateTime(row.item.updated_at) }}
                  </template>
                  <!-- <template v-slot:cell(actions)="row">
                    <button type="button"  
                      class="btn btn-sm btn-warning btn-icon-text"
                      @click="openEditModal(row.item.uuid, row.item)">
                      Info
                      <i class="mdi mdi-file-check btn-icon-append"></i>
                    </button>
                    <br>
                    <br>

                    <button type="button"  
                      class="btn btn-sm btn-danger btn-icon-text"
                      @click="openDeleteModal(row.item.uuid, row.item)">
                      Deactive
                      <i class="mdi mdi mdi-delete-variant btn-icon-append"></i>
                    </button>
                    <br>
                  </template>							 -->
                </b-table>
                <b-pagination
                  v-model="page"
                  :total-rows="totalRowsCourier"
                  :per-page="limit"
                  align="fill"
                  size="md"
                  class="mb-1"
                  v-on:click.native="callback"
                ></b-pagination>
                <b-row class="pt-4">
                  <p class="col-lg-12 mb-1">Total Data : {{ dataCourier.length }}</p>                
                  <p class="col-lg-12 mb-1">Total Data per Page : {{ totalRowsCourier }}</p>              
                  <p class="col-lg-12 mb-1">Total Page : {{ lastPageCourier }}</p>
                </b-row>
              </b-tab>
            </b-tabs>                          
					</div>
				</div>
      </div>      
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",			
			tableConfig : packageGlobals.table,
      dataOutlet: [],
      totalRowsOutlet: null,
      lastPageOutlet: null,
      
      dataCourier: [],
      totalRowsCourier: null,
      lastPageCourier: null,      
      dataHeader: [],      
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      form: {
        name : '',
        telp : '',
        address : '',
        latitude : '',
        longitude : '',
      },						
      fieldsOutlet: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'name', label: 'Name', class: "text-center", sortable: true},
				{key: 'address', label: 'Address', class: "text-center", sortable: true},        
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
      fieldsCourier: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'delivery_types', label: 'Type', class: "text-center", sortable: true},        
				{key: 'fullname', label: 'Fullname', class: "text-center", sortable: true},        
				{key: 'email', label: 'Email', class: "text-center", sortable: true},
        {key: 'handphone', label: 'Telp', class: "text-center", sortable: true},
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],      
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAllOutlet('-', currenPages, this.limit)
    },
    getHeader : function () {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      });

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
      this.$route.params.uuid
			axios.get(`${packageStore.mainApi}/api/web/master/villages/${this.$route.params.uuid_villages}`,packageGlobals.configAxios)
			.then((response) => {
        this.dataHeader = response.data.result[0]
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      })
    },    
    getAllOutlet: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      });

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
      this.$route.params.uuid
			axios.get(`${packageStore.mainApi}/api/web/master/outlet/cms?search=${search}&page=${page}&limit=${limit}&key=${this.$route.params.uuid_villages}`,packageGlobals.configAxios)
			.then((response) => {
        console.log(response.data.result.data);
        this.dataOutlet = response.data.result.data
        this.totalRowsOutlet = response.data.result.total
        this.lastPageOutlet = response.data.result.lastPage
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      })
    },
    getAllCourier: function (search, page, limit) {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/courier?search=${search}&page=${page}&limit=${limit}&uuid_villages=${this.$route.params.uuid_villages}&flag_assignments=villages`,packageGlobals.configAxios)
			.then((response) => {
        this.dataCourier = response.data.result.data
        this.totalRowsCourier = response.data.result.total
        this.lastPageCourier = response.data.result.lastPage
        loader.hide()
			})				
      .catch(function () {
        // this.responseAxiosError(error)
        loader.hide();        
      })	
    },    
    searchData: function() {
      this.getAllOutlet(this.search,this.page, this.limit)      
    },
    openLink: function (routeLink, item) {
      this.$router.push(`/master/area/provinces/${this.$route.params.uuid_provinces}/regencies/${this.$route.params.uuid_regencies}/district/${this.$route.params.uuid_district}/villages/${this.$route.params.uuid_villages}/outlet/${item.uuid}/${routeLink}`)      
    },    		
    openAddModal: function () {
      this.form.name = ""
      this.titleModal = "Add Outlet"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Outlet"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.name = item.name
      this.modalForm = true
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        });
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/master/outlet/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        }
      })
    },		
    sendPayload: function () {
      let checkField = packageGlobals.checkField(
        [
          {value : this.form.name, message : 'field name is required', key:'name'}        
        ]
      )
      var flag = checkField.flag, message = checkField.message
      if (flag == 0) {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        });

        if (this.stateCrud == "add") {
					this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/master/provinces`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/master/outlet`,this.form)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        } else if (this.stateCrud == "edit") {
					this.updated_by = localStorage.getItem("uuid")					
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/master/outlet/${this.idKey}`,this.form)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        }
        // loader.hide()
      }
      else
      {
        this.$swal.fire({
          title: "Ups, There is a mistake",
          text: message,
          icon: "error",
          confirmButtonText: "Ok",
        });        
      }      
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAllOutlet('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAllOutlet('-', this.page, this.limit)
    this.getAllCourier('-', this.page, this.limit)
		this.getHeader()    
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>